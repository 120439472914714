import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const Cover = styled.div`
  position: absolute;
  z-index: 1;
  background-color: rgba(87, 87, 89, 0.85);
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
`

const useBgImg = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImg: file(relativePath: { eq: "standalone/floorplan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data.backgroundImg
}

const BgImg = () => {
  const img = useBgImg()

  return (
    <>
      <Cover
        style={
          {
            // boxShadow: "inset 0 0 2000px rgba(255, 255, 255, .5)",
            // filter: "blur(10px)",
          }
        }
      />
      <Img
        fluid={img.childImageSharp.fluid}
        style={{
          position: "absolute",
          zIndex: 0,
          width: "100vw",
          height: "100%",
          top: 0,
          left: 0,
          // filter: "blur(3px)",
        }}
      />
    </>
  )
}

export default BgImg
